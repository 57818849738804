// Import Library
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { differenceInYears } from 'date-fns';

// Import Web Api
import posterServices from '../../dataservices/posterServices'

// Import Config
import config from "../../config";

const title = "Destacad@s";
const desc = "";

const currentDate = new Date();

const Story = (props) => {

    const { countryCode } = props
    const [topProfile, setTopProfile] = useState([]);

    const fetchStartValues = async () => {
        const responseTopProfileByCountry = await posterServices.getTopProfileByCountry(countryCode);
        if (!responseTopProfileByCountry.code) {
            setTopProfile(responseTopProfileByCountry)
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    return (
        <section className="story-section padding-tb bgimg" style={{ backgroundImage: "url(/assets/images/bg-img/02.jpg)" }}>
            <div className=" container">
                <div className="section-header">
                    <h2>{title}</h2>
                    <p>{desc}</p>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center g-4 row-cols-xl-3 row-cols-sm-2 row-cols-1">
                        {topProfile.map((value, index) => (
                            <div className="col" key={index}>
                                <div className="story-item lab-item">
                                    <div className="lab-inner">
                                        <div className="lab-thumb">
                                            <Link to="/blog-single"><img src={`${process.env.REACT_APP_URL_MEDIA}${value.photos[0].path}/${value.photos[0].fileName}`} alt={`${value.alias}`} /></Link>
                                        </div>
                                        <div className="lab-content">
                                            <h4>{value.alias}</h4>
                                            <div className="lab-content-author">
                                                <div className="left">
                                                    <Link to={`${config.url.whatsapp}/${value.phoneNumber}`} target="_blank" rel="noopener noreferrer"><i className="icofont-brand-whatsapp" style={{ fontSize: '50px' }}></i></Link>
                                                </div>
                                                <div className="right">
                                                    <span>Pais: {value.nameCountry}</span>
                                                    <span>Edad: {differenceInYears(currentDate, value.birthDate)}</span>
                                                    <span>Ciudad: {value.nameCityPublisher}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Story;